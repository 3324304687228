// IMPORTS
@import "colors";
@import "borders";
@import "buttons";
@import "fonts";
@import "cards";
@import "navigation";
@import "rfs";

// RFS TEXT
h1 {@include font-size(4rem);}
h2 {@include font-size(2rem);}
h3 {@include font-size(1.75rem);}
h4 {@include font-size(1.5rem);}
h5 {@include font-size(1.25rem);}
h6 {@include font-size(1rem);}
p.lead {@include font-size(1.5rem);}

body {background-color:$white;color:$black;}
section.expertises.header,
section.private-label.header,
section.careers.header {
  background: rgb(247,247,247);
  background: linear-gradient(180deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 50%, rgba(255,255,255,1) 50%);
}
section.brands.header
section.about.header {
  background: rgb(247,247,247);
}

.career blockquote {margin-bottom:4rem;font-size: 1.3rem;}
.career h3 {font-size:1.4rem;}

.vacancy  {border: 1px solid $mgrey;transition: 0.75s;height: 100%;}
.vacancy .lead {min-height: 75px;}
.vacancy:hover  {border: 1px solid $red;}
.vacancy:hover .lead {color:$red;transition: 0.75s;}

.image-divider {
  height:33vh;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}


.accordion-button:not(.collapsed) {
    color: $red;
    background-color: $lgrey;
}

.commitments {
  padding-top: 3rem;
  padding-bottom: 50vh;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 250%;
}
@media (min-width: 768px) {
  .commitments {
    background-size: cover;
  }
}

/* MODAL SUSTAINABILITY */
.modal-sustainability .modal-content {padding:2rem 3rem;}


/* HEADER CAREERS */

.header-careers {
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
}

.header-careers .text {
  width:100%;
  padding-top:20vh;
}

@media (min-width: 768px) {
  .header-careers {
    height:500px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .header-careers .text {
    padding: 0 0.75rem 0 0.75rem;
  }
}

@media (min-width: 992px) {
  .header-careers {
    height:500px;
    background-position: calc((100vw - 960px)/2) 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header-careers .text {
    margin-left: calc((100vw - 992px)/4);
    width:75%;
  }
}

@media (min-width: 1200px) {
  .header-careers {
    height:500px;
    background-position: calc((100vw - 1140px)/2) 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header-careers .text {
    margin-left: calc((100vw - 1140px)/4);
  }
}

@media (min-width: 1400px) {
  .header-careers {
    height:500px;
    background-position: calc((100vw - 1320px)/2) 0;
    background-repeat: no-repeat;
    background-size: calc((100vw - 1320px)/2 + 1320px);
  }
  .header-careers .text {
    margin-right: calc((100vw - 1320px)/4);
  }
}


/* HEADER ABOUT */
@media (min-width: 992px) {
  .header-brands .about-intro {
    width: 50vw;
  }
}


/* HEADER BRANDS */

.header-brands {
  height:400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.header-brands .text img  {
  width: 150px;
}

@media (min-width: 768px) {
  .header-brands {
    height:500px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .header-brands .text {
    margin-right: calc((100vw - 720px) / 2);
  }
  .header-brands .text img  {
    width: inherit;
  }
}

@media (min-width: 992px) {
  .header-brands {
    height:500px;
    background-position: right calc((100vw - 960px) / 2) top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .header-brands .text {
    margin-right: calc((100vw - 960px) / 3);
  }
}

@media (min-width: 1200px) {
  .header-brands {
    height:500px;
    background-position: right calc((100vw - 1140px) / 2) top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .header-brands .text {
    margin-right: calc((100vw - 1140px) / 3);
  }
}

@media (min-width: 1400px) {
  .header-brands {
    height:500px;
    background-position: right calc((100vw - 1320px) / 2) center;
    background-repeat: no-repeat;
    background-size: calc((100vw - 1320px)/2 + 1320px);
  }
  .header-brands .text {
    margin-right: calc((100vw - 1320px) / 3);
  }
}

/* HEADER PRIVATE LABEL */

.header-private-label {
  height:500px;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-private-label .text {
  padding: 0 0.75rem 0 0.75rem;
}

@media (min-width: 768px) {
  .header-private-label {
    height:500px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header-private-label .text {
    margin-left: calc((100vw - 768px)/4);
  }
}


@media (min-width: 992px) {
  .header-private-label {
    height:500px;
    background-position: calc((100vw - 960px)/2) 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header-private-label .text {
    margin-left: calc((100vw - 992px)/4);
  }
}

@media (min-width: 1200px) {
  .header-private-label {
    height:500px;
    background-position: calc((100vw - 1140px)/2) 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header-private-label .text {
    margin-left: calc((100vw - 1140px)/4);
  }
}

@media (min-width: 1400px) {
  .header-private-label {
    height:500px;
    background-position: calc((100vw - 1320px)/2) 0;
    background-repeat: no-repeat;
    background-size: calc((100vw - 1320px)/2 + 1320px);
  }
  .header-private-label .text {
    margin-right: calc((100vw - 1320px)/4);
  }
}



/* HEADER EXPERTISES*/

.header-expertises {
  height:500px;
  background-repeat: no-repeat;
  background-size: contain;
}

.header-expertises .text {
  -webkit-transform: translateY(30%);
  transform: translateY(30%);
  margin-top: -10px;
  width:100%;
  padding: 0 0.75rem 0 0.75rem;
}

@media (min-width: 375px) {
  .header-expertises .text {
    -webkit-transform: translateY(40%);
    transform: translateY(40%);
  }

}

@media (min-width: 768px) {
  .header-expertises {
    height:500px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header-expertises .text {
    margin-left: calc((100vw - 720px)/4);
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    margin-top: -10px;
    width:65%;
  }
}


@media (min-width: 992px) {
  .header-expertises {
    height:500px;
    background-position: calc((100vw - 960px)/2) 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header-expertises .text {
    margin-left: calc((100vw - 960px)/4);
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    margin-top: -10px;
    width:65%;
  }
}

@media (min-width: 1200px) {
  .header-expertises {
    height:500px;
    background-position: calc((100vw - 1140px)/2) 0;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .header-expertises .text {
    margin-left: calc((100vw - 1140px)/4);
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    margin-top: -10px;
    width:50%;
  }
}

@media (min-width: 1400px) {
  .header-expertises {
    height:500px;
    background-position: calc((100vw - 1320px)/2) 0;
    background-repeat: no-repeat;
    background-size: calc((100vw - 1320px)/2 + 1320px);
  }
  .header-expertises .text {
    margin-right: calc((100vw - 1320px)/4);
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    margin-top: -10px;
    width:30%;
  }
}

/* FOOTER */
.footer {color:$dgrey;}
.postscript a:link {color:$black;}
.accordion-button, .accordion-item {background:$lgrey;}


/* CAROUSEL */
@media (max-width: 767px) {
    .carousel-inner .carousel-item > div {
        display: none;
    }
    .carousel-inner .carousel-item > div:first-child {
        display: block;
    }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {

    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(25%);
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
      transform: translateX(-25%);
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
  transform: translateX(0);
}

/* CAROUSEL HISTORY NAVIGATION */
.history .carousel .carousel-control-next {
    align-items: end;
    justify-content: end;
}
.history .carousel .carousel-control-prev {
    align-items: end;
    justify-content: start;
}
.history .carousel-control-next-icon,
.history .carousel-control-prev-icon {
    width: 4rem;
    height: 4rem;
    margin: 0;
}
@media (min-width: 768px) {
  .history .carousel-control-next-icon,
  .history .carousel-control-prev-icon {
      margin: 0 0.25rem;
  }
}
