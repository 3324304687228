/* NAVIGATION */
.navbar .megamenu {
  padding: 0rem;
}

.navbar-toggler:focus {
  outline: none !important;box-shadow: none;
}

.accordion-button:focus {
  outline: none !important;box-shadow: none;
}


.dropdown-menu {
  border-top: 1px solid $lgrey;
  border-bottom: 1px solid $lgrey;
  border-left: 0;
  border-right:0;
  border-radius: 0;
}
.dropdown-item {
  color: $black !important;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: $black;
  background-color: $lgrey;
}

.dropdown-item.brands:focus, .dropdown-item.brands:hover {
    color: #000000;
    background-color: #FFFFFF;
}

.dropdown-menu .col.brands {
  text-align: center;  
}

@media all and (min-width: 992px) {
  .navbar .has-megamenu {
    position:static!important;
  }
  .navbar .megamenu {
    left:0;
    right:0;
    width:100%;
    margin-top:0;
  }
  .navbar-light .navbar-nav li {
    border-bottom:3px solid $white;
  }
  .navbar-light .navbar-nav li:hover {
    color: $red;
    border-bottom:3px solid $red;
  }
  .dropdown-menu {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  }
}

@media(max-width: 991px) {
  .navbar {
    border-bottom: 1px solid $red;
  }
  .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse {
    overflow-y:auto;
    max-height:90vh;
    margin-top:10px;
  }
}

.navbar-light .navbar-nav .nav-link {
  text-transform: uppercase;
  font-weight:900;
  color:$black;
}
.navbar-light .navbar-nav .nav-link.contact {
  color:$red;
}

.navbar-light .navbar-toggler {
  color:$black;
  border-color:transparent;
}

.navbar-toggler:focus {
  box-shadow:none;
}

.navbar-toggler {
  padding: 0;
  #nav-icon {
    width: 30px;
    height: 22px;
    position: relative;
    margin: 0 auto;
    transform: rotate(0deg);
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 50%;
      background: $black;
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }
  }
  &[aria-expanded="false"] {
  #nav-icon {
    span:nth-child(even) {
      left: 50%;
    }
    span:nth-child(odd) {
      left: 0px;
    }
    span:nth-child(1),
    span:nth-child(2) {
      top: 0px;
    }
    span:nth-child(3),
    span:nth-child(4) {
      top: 9px;
    }
    span:nth-child(5),
    span:nth-child(6) {
      top: 18px;
    }
  }
}

&[aria-expanded="true"] {
  #nav-icon {
    span:nth-child(1),
    span:nth-child(6) {
      transform: rotate(45deg);
    }
    span:nth-child(2),
    span:nth-child(5) {
      transform: rotate(-45deg);
    }
    span:nth-child(1) {
      left: 5px;
      top: 7px;
    }
    span:nth-child(2) {
      left: calc(50% - 5px);
      top: 7px;
    }
    span:nth-child(3) {
      left: -50%;
      opacity: 0;
    }
    span:nth-child(4) {
      left: 100%;
      opacity: 0;
    }
    span:nth-child(5) {
      left: 5px;
      top: 12px;
    }
    span:nth-child(6) {
      left: calc(50% - 5px);
      top: 12px;
    }
  }
}
}
