a {color:$red;text-decoration:none;}
a:link, a:visited, a:hover, a:active {text-decoration:none;color:$red;}
a.link-dark {color:$black;}
a.link-light {color:$white;}
.nav-link {color:$black;}
.nav-link:hover {color: $red;}

a.btn {border-radius:0px;}

btn:focus, a:focus {outline: none;}

a.btn-primary, .btn-primary,
.btn-primary:focus, .btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled):active {background-color:$red;border-color:$red;color:$white;font-weight:700;}
a.btn-primary:hover, .btn-primary:hover {background-color:$red;border-color:$red;color:$lgrey;font-weight:700;}
.btn-primary.disabled, .btn-primary:disabled {color:$black; background-color:$red; border-color:$red;}

a.btn-secondary, .btn-secondary,
.btn-secondary:focus, .btn-secondary:active,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled):active {background-color:$black;border-color:$black;color:$white;font-weight:700;}
a.btn-secondary:hover, .btn-secondary:hover {background-color:$black;border-color:$black;color:$lgrey;font-weight:700;}

a.btn-light, .btn-light,
.btn-light:focus, .btn-light:active,
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled):active {background-color:$mgrey;border-color:$mgrey;color:$black;font-weight:700;}
a.btn-light:hover, .btn-light:hover {background-color:$mgrey;border-color:$mgrey;color:$white;font-weight:700;}

a.btn-dark, .btn-dark,
.btn-dark:focus, .btn-dark:active,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled):active {background-color:$black;border-color:$black;color:$white;font-weight:700;}
a.btn-dark:hover, .btn-dark:hover {background-color:$black;border-color:$black;color:$white;font-weight:700;}
