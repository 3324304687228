$red: #CE0E2D;
$black: #000000;
$white: #ffffff;
$lgrey: #F7F7F7;
$mgrey: #ABABAB;
$dgrey: #666666;

.red {color:$red;}
.black {color:$black;}
.white {color:$white;}
.lgrey {color:$lgrey;}
.mgrey {color:$mgrey;}
.dgrey {color:$dgrey;}
.text-light {background-color:$white !important;}
.text-dark {background-color:$black !important;}

.bg-red {background-color:$red;}
.bg-black {background-color:$black;}
.bg-white {background-color:$white;}
.bg-lgrey {background-color:$lgrey;}
.bg-mgrey {background-color:$mgrey;}
.bg-dgrey {background-color:$dgrey;}
.bg-light {background-color:$lgrey !important;}
.bg-dark {background-color:$black !important;}
