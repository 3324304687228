/* MONTSERRAT FONT */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');


$text : 'Open Sans', sans-serif;
$text-regular: 400;
$text-medium: 600;
$text-bold: 700;

.text {font-family: $text;font-weight: $text-regular;font-style: normal;}
.text-bigger {font-size: 1.125rem;}
.text-medium {font-family: $text;font-weight: $text-medium;font-style: normal;}
.text-bold {font-family: $text;font-weight: $text-bold;font-style: normal;}
.text-shadow {text-shadow:1px 1px 2px #0000004a;}
.text-underline {border-bottom: 1px solid $red;}

// DEFAULT FONT USE
body {font-family:$text;color:$dgrey;}
h1, .h1 {font-family:$text;font-weight:$text-bold;}
h2, .h2 {font-family:$text;font-weight:$text;}
h3, .h3 {font-family:$text;font-weight:$text-bold;}
h4, .h4 {font-family:$text;}
h5, .h5 {font-family:$text;font-weight:$text-bold;}
h6, .h6 {font-family:$text;font-weight:$text-bold;}
p {font-family:$text;font-weight:$text;}
p.lead {font-family:$text;font-weight:$text;}
p.lead strong {font-weight:$text-bold;}
small {font-family:$text;font-weight:$text;}
